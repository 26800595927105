#error-symbol {
    font-size: 50px;
    border: 1px solid;
    border-radius: 50%;
    height: 55px;
    width: 55px;
    margin: auto;
    
}

.internet-error {
  height: 30px;
  background: #ff8100;
  margin-top: 0;
  font-size: 20px;
  flex-direction: column;
  justify-Content: center;
  align-Items: center;
  text-align: center;
}

.internet-error p {
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  margin: 0;
}